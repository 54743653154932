import React, { Fragment } from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faCampground, faGamepad } from "@fortawesome/free-solid-svg-icons";

type MyState = {};
type MyProps = {
  ServerName: string;
  ServerIP: string;
  JoinIP: string;
  ServerType: string;
  ServerPort: string;
  JoinPort: string;
  ServerMap: string;
  ServerVersion: string;
  ServerID: string;
  PlayersOnline: number;
  Region: string;
};
export class ServerBox extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.HideAnswer = this.HideAnswer.bind(this);
    this.state = {};
  }
  componentDidMount() {}
  HideAnswer(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }
  // Extremely hacky
  convertToGuidesLink(input: string, mapName: string) {
    if (mapName === "PANDAHUT RP LARGE PEI") {
      return "RP-13-(PEI-Large)";
    }
    switch (input) {
      case "Roleplay":
        return "RP-10-(Washington)";
    }
    if (input.startsWith("Unturnov 1.5")) {
      return "Unturnov-1.5-(12,-14)";
    }
    if (input.length === 0 || input.includes(" ") === false) {
      return input;
    }
    switch (input.split(" ")[0]) {
      case "Normal":
        return "Normal-Economy";
      case "Unturnov 1.5":
        return "Unturnov-1.5-(12,-14)";
      case "Unturnov":
        return "Unturnov-1.25-(19)";
      case "Roleplay":
        return "RP-10-(Washington)";
    }
    return input.split(" ")[0];
  }

  render() {
    const gamePadEl = <i className="btnicon"><FontAwesomeIcon icon={faGamepad} /></i>;
    const guideBookEl = <i className="btnicon"><FontAwesomeIcon icon={faBookOpen} /></i>;
    const lobbyBookEl = <i className="btnicon"><FontAwesomeIcon icon={faCampground} /></i>;

    return (
      <div className="card show">
        <img
          src={`https://phcdn.pandahut.net/Maps/${this.props.ServerMap.split(
            " "
          ).join("")}.png`}
          alt={`${this.props.ServerMap}`}
        />
        <div className="card-title">
          <h2>{this.props.ServerName}</h2>
        </div>
        <div className="card-flap">
          <div className="card-description">
            <ul className="alignLeft">
              <li>
                IP: <strong>{this.props.JoinIP}</strong> Port:{" "}
                <strong>{this.props.JoinPort}</strong>
              </li>
              <li>
                <span>Map:</span> <strong>{this.props.ServerMap}</strong>
              </li>
              <li>
                <span>Player Slots:</span> <strong>220</strong>
              </li>
              <li>
                <span>Style:</span> <strong>{this.props.ServerType}</strong>
              </li>
              <li>
                <span>Region:</span> <strong>{this.props.Region}</strong>
              </li>
              <li>
                <span>Players:</span>{" "}
                <strong>{this.props.PlayersOnline}/220</strong>                
              </li>
            </ul>
	    <ul className="alignLeft-buttons">
		<li className="serverboxbuttons">
		  <a href={`https://guides.pandahut.net/${this.convertToGuidesLink(this.props.ServerType, this.props.ServerMap)}`}><button className="button btn cyan">{guideBookEl}View Guide</button></a>				
		  <a href="https://join.pandahut.net/s=lobby.pandahut.net:27015"><button className="button btn orange">{lobbyBookEl}<span> Join Lobby</span></button></a>  				
		  <a href={`https://join.pandahut.net/s=${this.props.JoinIP}:${this.props.JoinPort}`}><button className="button btn red">{gamePadEl}<span> Join Server</span></button></a>          				
		</li>				
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default ServerBox;
